import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Label,
  CardText
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import defaultService from "../../services/defaultService";
import ModalConfirm from "components/ModalConfirm";
import ApiCalendar from 'react-google-calendar-api';

const config = {
  "clientId": "1076445512781-1gnfdr4p19tfhj97o9jd8grrk2gdnk9v.apps.googleusercontent.com",
  "apiKey": "AIzaSyCQW0U8rFGPKSF-Le3Qo9ZLU9vsDT6NBfs",
  "scope": "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.settings.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}
const apiCalendar = new ApiCalendar(config)
const table = "enquetes";

function VerEnquetesScreen({ nameRoute, old, newer }) {

  const [isOpen, setIsOpen] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [items, setUsers] = useState([]);
  const [votacoes, setVotacoes] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [localUser, setLocalUser] = useState({});
  const [resposta, setResposta] = useState("");
  const notificationAlert = React.useRef();
  const localUser_ = JSON.parse(localStorage?.getItem("user"));


  useEffect(() => {
    listItems();
  }, []);

  const listItems = async () => {

    const localUser_ = JSON.parse(localStorage?.getItem("user"));
    setLocalUser(localUser_);

    var items_ = await defaultService.list(table);
    items_?.[0]?.id && setUsers(items_);
    console.log({items_});

    var votacoes_ = await defaultService.listSelect("votacoes");
    votacoes_?.[0]?.id && setVotacoes(votacoes_);

  }

  const onSubmit = async (id) => {

    var form = {};

    form = {
      table: 'votacoes',
      idEnquete: id,
      resposta: resposta,
      cpf_socio: localUser_.cpf,
    }

    const resp = await defaultService.create(form);
    setIsOpen(false);

    resp?.status == 200 ?
      notify("tr", 2, 'Votação feita com sucesso!')
      : notify("tr", 3, 'Erro ao votar!');

    listItems();

  }

  const deleteItem = async (e) => {
    setModalConfirm(false);

    if (e != "sim") {
      setCurrentItem({});
      return
    }

    const resp = await defaultService.delete(currentItem.id, table);

    resp?.status == 200 ?
      notify("tr", 2, "Deletado com sucesso!")
      : notify("tr", 3, 'Erro ao deletar!');

    setCurrentItem({});

    listItems();
  }

  const editItem = async (userData) => {

    const resp = await defaultService.update(currentItem?.id, userData);
    resp?.status == 200 ?
      notify("tr", 2, "Atualizado com sucesso!")
      : notify("tr", 3, 'Erro ao atalizar!');

    setCurrentItem({});
    setIsOpen(false);

    listItems();
  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const orderByDate = (a, b) => {
    return new Date(b.data_evento) - new Date(a.data_evento);
  }

  const dateIsOlderThenToday = (date) => {
    return new Date(date.data_evento) < new Date();
  }

  const dateIsNewerThenToday = (date) => {
    return new Date(date.data_evento) >= new Date();
  }

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        <ModalConfirm isOpen={modalConfirm} toggle={deleteItem} />
        <Row>

          {(items.sort(orderByDate).filter(dateIsNewerThenToday)?.length < 1 && newer) && <CardText tag="h5" color="secondary" className="text-center ml-3">Não há novos eventos</CardText>}

          {(items && newer) && items.sort(orderByDate).filter(dateIsNewerThenToday).map((item) => (

            <Col md="4">
              <Card className="card-user mb-3" key={item?.id + 'news'} style={{ minHeight: "140px" }}>
                <CardBody style={{ minHeight: "100px", }}>
                  <Row className="align-items-center justify-content-center">

                    <Col style={{ maxHeight: "220px", minHeight: "140px" }}>
                      <Row className="align-items-center mb-3">
                        <Col>
                          <CardText tag={"h6"} className="card-text">{item?.nome}</CardText>
                        </Col>

                      </Row>
                      <CardText className="card-text text-start">Dia {new Date(item.data_evento).toLocaleDateString('pt-BR')} às {new Date(item.data_evento).toLocaleTimeString('pt-BR')}</CardText>
                      <CardText className="card-text"> {item?.tipo_evento}</CardText>
                    </Col>


                  </Row>

                </CardBody>
                <Button
                  type="link"
                  color="primary"
                  className="btn primary"
                  style={{ width: "100%", marginBottom: 0 }}
                  href={item?.url} target="_blank"
                //onClick={() => participar(item?.id, item?.room)}
                //href={`https://assembleia.agenciaiter.com.br/?room=${item.room}&nome=${localUser_?.nome}&type=audience`} target="_blank"
                >
                  Participar
                </Button>
              </Card>
            </Col>)
          )}

          {(items && old) && items.sort(orderByDate).filter(dateIsOlderThenToday).map((item) => (

            <Col md="4">
              <Card className="card-user mb-3" key={item?.id + 'news'} style={{ minHeight: "140px" }}>
                <CardBody style={{ minHeight: "100px", }}>
                  <Row className="align-items-center justify-content-center">

                    <Col style={{ maxHeight: "220px", minHeight: "140px" }}>
                      <Row className="align-items-center mb-3">
                        <Col>
                          <CardText tag={"h6"} className="card-text">{item?.nome}</CardText>
                        </Col>

                      </Row>
                      <CardText className="card-text text-start">Dia {new Date(item.data_evento).toLocaleDateString('pt-BR')} às {new Date(item.data_evento).toLocaleTimeString('pt-BR')}</CardText>
                      <CardText className="card-text"> {item?.tipo_evento}</CardText>
                    </Col>


                  </Row>

                </CardBody>
                <Button
                  type="link"
                  color="primary"
                  className="btn primary"
                  style={{ width: "100%", marginBottom: 0 }}
                  href={item?.url} target="_blank"
                //onClick={() => participar(item?.id, item?.room)}
                //href={`https://assembleia.agenciaiter.com.br/?room=${item.room}&nome=${localUser_?.nome}&type=audience`} target="_blank"
                >
                  Participar
                </Button>
              </Card>
            </Col>)
          )}

          {(items && !newer && !old) && items.sort(orderByDate).map((item) => (

            <Col sm="12" md="6" lg="6" key={item?.id}>
              <Card className="card-user mb-3" key={item?.id + 'news'} style={{ minHeight: "140px" }}>
                <CardBody style={{ minHeight: "100px", }}>
                  <Row className="align-items-center justify-content-center">

                    <Col style={{ maxHeight: "220px", minHeight: "140px" }}>
                      <CardText tag={"h6"} className="card-text">{item?.nome}</CardText>
                      <CardText className="card-text text-start"><small>Dia {new Date(item.criado_em).toLocaleDateString('pt-BR')}</small></CardText>
                      <CardText className="card-text"> {item?.descricao}</CardText>
                    </Col>


                  </Row>

                </CardBody>

                <CardFooter className="text-center border-top pt-2">
                  {votacoes?.find(votacao => votacao?.idEnquete == item?.id && votacao?.cpf_socio == localUser?.cpf) ?
                    <CardText tag={"p"} className="card-text text-center m-2 mb-4" >
                      Você votou <strong>{votacoes?.find(votacao => votacao?.idEnquete == item?.id && votacao?.cpf_socio == localUser?.cpf)?.resposta}</strong>
                    </CardText>
                    :
                    <Row className="align-items-center justify-content-center">
                      <Col md="8">
                        <FormGroup>
                          <Input
                            type="select"
                            name="ativo"
                            className="mt-1"
                            onChange={(e) => setResposta(e.target.value)}>
                            <option value="" disabled selected>---Escolher resposta---</option>
                            {item?.lista_opcoes?.split(',').map((opcao) => (
                              <option value={opcao}>{opcao}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <Button
                          color="primary"
                          className="btn primary"
                          onClick={() => onSubmit(item?.id)}>
                          Votar
                        </Button>
                      </Col>
                    </Row>}
                </CardFooter>
              </Card>
            </Col>)
          )}

        </Row>
      </div>
    </>
  );
}

export default VerEnquetesScreen;
