import React, { useState, useEffect } from "react";
import {
  Button,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import defaultService from "../../services/defaultService";
import { useNavigate } from "react-router-dom";

const table = "clientes";
function RecuperarSenhaScreen({ nameRoute }) {

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [localUser, setLocalUser] = useState({});
  const notificationAlert = React.useRef();


  useEffect(() => {
    listItems();
  }, []);

  const listItems = async () => {
    const localUser_ = JSON.parse(localStorage?.getItem("user"));
    setLocalUser(localUser_);
  
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsOpen(true);

    const form = new FormData();

    form.append('email', e.target['email'].value);
    
    notify("tr", 2, 'Enviaremos um email de recuperação para ' + e.target['email'].value)
    const resp = await defaultService.recuperarSenha(form);
    //notify("tr", 3, 'Erro ao cadastrar!');

  }

  const deleteItem = async (e) => {
    setModalConfirm(false);

    if (e != "sim") {
      setCurrentItem({});
      return
    }

    const resp = await defaultService.delete(currentItem.id, table);

    resp?.status == 200 ?
      notify("tr", 2, "Deletado com sucesso!")
      : notify("tr", 3, 'Erro ao deletar!');

    setCurrentItem({});

    listItems();
  }

  const editItem = async (userData) => {

    const resp = await defaultService.update(currentItem?.id, userData);
    resp?.status == 200 ?
      notify("tr", 2, "Atualizado com sucesso!")
      : notify("tr", 3, 'Erro ao atalizar!');

    setCurrentItem({});
    setIsOpen(false);

    listItems();
  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content mt-5">
        <Col md="8" style={{ margin: 'auto' }}>
          <NotificationAlert ref={notificationAlert} />

          <CardTitle className="m-3" tag="h5">Digite seu e-mail para recuperar a senha</CardTitle>

          <Form onSubmit={onSubmit} datatype="multipart/form-data">
            <Input type="hidden" name="id" defaultValue={currentItem?.id} />

            <Input
              type="hidden"
              name="idEmpresa"
              defaultValue="3" />

            <Col md="12">
              <FormGroup>
                <label htmlFor="exampleInputEmail1">
                  Email
                </label>
                <Input
                  defaultValue={currentItem?.email}
                  type="email"
                  name="email"
                />
              </FormGroup>
            </Col>


            <Row>
              <div className="update ml-auto mr-auto">
                <Button
                  className="btn-round"
                  color="primary"
                  type="submit"
                  //disabled={isOpen}
                >
                  {currentItem.id ? "Atualizar" : "Enviar"}
                </Button>
              </div>
            </Row>
          </Form>
          <a className="btn-link  color-primary m-2" style={{ color: '#0C407C' }}
            color="primary"
            type="link"
            href={"/login"}
          >
            Voltar
          </a>
        </Col>
      </div>
    </>
  );
}

export default RecuperarSenhaScreen;
